import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Box, ButtonGroup, Container, Typography } from "@mui/material";
import ForecastActions from "../../Components/ForecastActions";
import Header from "../../Components/Header";
import Button from "@mui/material/Button";
import { hardcodedPeriods } from "../ProductionPrognosisPeriods";
import { grey } from "@mui/material/colors";
import { ProductionPark } from "api/models/ProductionPark";
import {
  ProductionForecast,
  ProductionForecastWeights,
  PrognoseChartAvgAndForecastLines,
  PrognoseChartServiceProviderLines,
} from "api/models/ProductionForecast";
import {
  ForecastColumnType,
  TableMatrixProduction,
} from "api/models/TableMatrix";
import {
  aggregateForecastData,
  calculateCapacities,
  calculatePeriodData,
  calculateSummaryRow,
  calculateTotalValues,
  handleCellClick,
  handleConfirmForecast,
  handleMouseDown,
  handleMouseUp,
  handlePriceAreaClick,
  handleSaveForecast,
  handleSetCorrection,
  handleSetParkCorrection,
  handleUnconfirmForecast,
} from "./ProductionForecastService";
import FixedTable from "./ProductionForecastFixedTable";
import OverallTable from "./ProductionForecastOverallTable";
import ProductionParkTable from "./ProductionForecastParkTable";
import { useAppDispatch } from "hooks";
import ForecastsAbsoluteErrorChart from "./ForecastsAbsoluteErrorChart";
import PastForecastsChart from "./PastForecastsChart";
import ProductionForecastLineChart from "../ProductionForecastLineChart";
import ForecastWeights from "./ForecastWeights";

interface EditProductionForecastProps {
  forecast: ProductionForecast;
  productionParks: ProductionPark[];
  priceAreas: string[];
  setSnackbar: (snackbar: { type: string; message: string }) => void;
}

const EditProductionForecast = ({
  forecast,
  productionParks,
  priceAreas,
  setSnackbar,
}: EditProductionForecastProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedPriceArea, setSelectedPriceArea] = useState<number | null>(0);
  const [tableMatrix, setTableMatrix] = useState<TableMatrixProduction[]>([]);
  const [overallValues, setOverallValues] = useState<any[]>([]);
  const [saving, setSaving] = useState(false);
  const [averageLine, setAverageLine] = useState<number[]>([]);
  const [forecastLine, setForecastLine] = useState<number[]>([]);
  const [meteologicaLine, setMeteologicaLine] = useState<number[]>([]);
  const [aiolosLine, setAiolosLine] = useState<number[]>([]);
  const [forecaLine, setForecaLine] = useState<number[]>([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [weightChangeTrigger, setWeightChangeTrigger] = useState(0);

  let globalForecastWeights: Record<string, ProductionForecastWeights> = {};
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const uniquePriceAreas = Array.from(new Set(priceAreas)).sort((a, b) => {
    if (a === "EE") return -1;
    if (b === "EE") return 1;
    return 0;
  });

  const availableForecasts = {
    SOLAR: ["foreca", "aiolos"],
    WIND: ["aiolos", "meteologica"],
    HYBRID: ["aiolos", "meteologica"],
  };

  //if is editable
  useEffect(() => {
    if (forecast && !dayjs(forecast.prognoseDate).isBefore(dayjs())) {
      setIsEditable(true);
    } else {
      setIsEditable(true);
    }
  }, [selectedPriceArea]);

  //chart
  useEffect(() => {
    const avgLine: number[] = [];
    const frcLine: number[] = [];
    const aiolosLine: number[] = [];
    const meteologicaLine: number[] = [];
    const forecaLine: number[] = [];

    if (tableMatrix.length > 0) {
      tableMatrix[selectedPriceArea].periods.map(
        (row: PrognoseChartAvgAndForecastLines) => {
          avgLine.push(Number(row.total.toFixed(2)));
          frcLine.push(Number(row.forecast.toFixed(2)));
        },
      );
    }

    if (overallValues.length > 0) {
      const priceAreaIndexMap = uniquePriceAreas.reduce(
        (acc, area, index) => {
          acc[area] = index;
          return acc;
        },
        {} as Record<string, number>,
      );

      const selectedAreaValues = overallValues.find((value) => {
        const areaIndex = priceAreaIndexMap[value.priceArea];
        return areaIndex === selectedPriceArea;
      });

      if (selectedAreaValues) {
        selectedAreaValues.periods.forEach(
          (row: PrognoseChartServiceProviderLines) => {
            forecaLine.push(Number(row.forecaData.toFixed(2)));
            aiolosLine.push(Number(row.aiolosData.toFixed(2)));
            meteologicaLine.push(Number(row.meteologicaData.toFixed(2)));
          },
        );
      }
    }
    setMeteologicaLine(meteologicaLine);
    setAiolosLine(aiolosLine);
    setForecaLine(forecaLine);
    setAverageLine(avgLine);
    setForecastLine(frcLine);
  }, [tableMatrix, overallValues]);

  //table data, parks and total
  useEffect(() => {
    const tableMatrixData: TableMatrixProduction[] = uniquePriceAreas.map(
      (area) => {
        const parks = productionParks.filter((park) => park.priceArea === area);
        const capacities = parks.map((park) =>
          calculateCapacities(park, forecast),
        );

        let totalValues = forecast.productionPrognoseValues.totalValues[area];
        if (!totalValues || totalValues.length === 0) {
          totalValues = capacities.flatMap((capacity) =>
            capacity.map((cap) => ({
              hour: cap.totalProductionSum.forecast.value,
              adjustment: cap.totalProductionSum.adjustment,
              value: cap.totalProductionSum.forecast.value,
            })),
          );
        }

        const forecastWeights = forecast.filtersJson[0].forecastWeights[area];

        return {
          priceArea: area,
          parks: parks.map((park, index) => ({
            productionPark: park,
            readings: capacities[index],
          })),
          periods: calculatePeriodData(totalValues),
          forecastWeights: forecastWeights,
        };
      },
    );

    setTableMatrix(tableMatrixData);
  }, [forecast, productionParks, priceAreas]);

  //overall
  useEffect(() => {
    const overallValues = uniquePriceAreas.map((area) => {
      const aggregatedData = aggregateForecastData(
        forecast.cellsJson,
        area,
        productionParks,
      );

      const periods = Object.values(aggregatedData).map((data) => ({
        datetime: data.datetime,
        forecaData: data.totalProduction.forecaData.value / 1000,
        aiolosData: data.totalProduction.aiolosData.value / 1000,
        meteologicaData: data.totalProduction.meteologicaData.value / 1000,
        forecast: data.totalProductionSum.forecast.value,
        adjustment: data.totalProductionSum.adjustment,
      }));

      return {
        priceArea: area,
        periods,
      };
    });

    setOverallValues(overallValues);
  }, [priceAreas, productionParks, forecast, hardcodedPeriods]);

  useEffect(() => {
    tableMatrix.forEach((_, rowIndex) => {
      calculateTotalValues(
        tableMatrix,
        setTableMatrix,
        selectedPriceArea,
        rowIndex,
      );
    });
  }, [tableMatrix, selectedPriceArea, weightChangeTrigger]);

  const DynamicButtonGroup = () => {
    return (
      <ButtonGroup>
        {uniquePriceAreas.map((area, areaIndex) => (
          <Button
            key={`PriceArea-${areaIndex}`}
            variant={selectedPriceArea === areaIndex ? "contained" : "outlined"}
            onClick={() =>
              handlePriceAreaClick(areaIndex, setSelectedPriceArea)
            }
            sx={{ padding: "0.4rem 2rem" }}
          >
            {area}
          </Button>
        ))}
      </ButtonGroup>
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        style={{ paddingBottom: 20 }}
      >
        <Header type="production" forecast={forecast} />
        <ForecastActions
          isEditable={isEditable}
          isVerified={forecast.isVerified}
          handleConfirmForecast={() => {
            handleConfirmForecast(forecast.id, setSnackbar, dispatch);
          }}
          handleSaveForecast={() =>
            handleSaveForecast(
              tableMatrix,
              forecast,
              navigate,
              setSaving,
              setSnackbar,
            )
          }
          handleUnconfirmForecast={() => {
            handleUnconfirmForecast(forecast.id, setSnackbar, dispatch);
          }}
          saving={saving}
        />
      </Box>
      {forecast &&
        productionParks.length > 0 &&
        priceAreas.length > 0 &&
        tableMatrix.length > 0 && (
          <>
            <Box style={{ paddingBottom: 40 }}>
              <Container
                maxWidth="xl"
                sx={{
                  background: "#f4f4f4",
                  margin: 0,
                  mt: "1rem",
                  padding: "1rem",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography component="p" fontSize="1.2rem">
                    {`${forecast.filtersJson[0].type} forecast`}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    component="h5"
                    fontWeight={500}
                    sx={{ mr: "0.4rem" }}
                  >
                    {`${productionParks.length} solar parks including ${forecast.meteringPointsCount} metering points`}
                  </Typography>
                </Box>
              </Container>
            </Box>
            <Box display={"flex"} flexDirection={"row"}>
              <Box style={{ paddingBottom: 20 }}>
                <Typography
                  component="h5"
                  fontWeight={500}
                  sx={{ mr: "1rem", mb: "0.6rem" }}
                >
                  Price area
                </Typography>
                {DynamicButtonGroup()}
              </Box>
              <Box style={{ paddingLeft: 25, paddingBottom: 20 }}>
                <Typography
                  component="h5"
                  fontWeight={500}
                  sx={{ mr: "1rem", mb: "0.6rem" }}
                >
                  Forecast weights
                </Typography>
                <ForecastWeights
                  tableMatrix={tableMatrix}
                  setTableMatrix={setTableMatrix}
                  globalForecastWeights={globalForecastWeights}
                  forecast={forecast}
                  setWeightChangeTrigger={setWeightChangeTrigger}
                  selectedPriceArea={selectedPriceArea}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: " space-between",
                alignItems: " flex-start",
              }}
              width={"100%"}
            >
              <Box
                display={"flex"}
                flexWrap={"nowrap"}
                width={"65%"}
                style={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: grey[400],
                  borderCollapse: "collapse",
                  position: "sticky",
                  top: "2rem",
                }}
              >
                <Box>
                  <FixedTable
                    tableMatrix={tableMatrix}
                    selectedPriceArea={selectedPriceArea}
                    isEditable={isEditable}
                    handleMouseDown={(event) =>
                      handleMouseDown(event, setIsMouseDown)
                    }
                    handleMouseUp={() => handleMouseUp(setIsMouseDown)}
                    handleSetCorrection={(value, rowIndex) =>
                      handleSetCorrection(
                        value,
                        rowIndex,
                        tableMatrix,
                        setTableMatrix,
                        selectedPriceArea,
                        () =>
                          calculateSummaryRow(
                            tableMatrix,
                            setTableMatrix,
                            selectedPriceArea,
                            rowIndex,
                          ),
                      )
                    }
                    hardcodedPeriods={hardcodedPeriods}
                  />
                </Box>
                <Box display={"flex"} overflow={"auto"}>
                  <OverallTable
                    overallValues={overallValues}
                    selectedPriceArea={selectedPriceArea}
                    availableForecasts={availableForecasts}
                    forecast={forecast}
                  />
                  <ProductionParkTable
                    tableMatrix={tableMatrix}
                    selectedPriceArea={selectedPriceArea}
                    isEditable={isEditable}
                    isMouseDown={isMouseDown}
                    availableForecasts={availableForecasts}
                    forecast={forecast}
                    handleMouseDown={(event) =>
                      handleMouseDown(event, setIsMouseDown)
                    }
                    handleMouseUp={() => handleMouseUp(setIsMouseDown)}
                    handleCellClick={(
                      type: ForecastColumnType,
                      parkIndex,
                      rowIndex,
                    ) =>
                      handleCellClick(
                        tableMatrix,
                        setTableMatrix,
                        overallValues,
                        setOverallValues,
                        selectedPriceArea,
                        parkIndex,
                        rowIndex,
                        type,
                      )
                    }
                    handleSetParkCorrection={(value, parkIndex, rowIndex) =>
                      handleSetParkCorrection(
                        value,
                        parkIndex,
                        rowIndex,
                        tableMatrix,
                        setTableMatrix,
                        selectedPriceArea,
                        () =>
                          calculateSummaryRow(
                            tableMatrix,
                            setTableMatrix,
                            selectedPriceArea,
                            rowIndex,
                          ),
                      )
                    }
                    overallValues={overallValues}
                    setOverallValues={setOverallValues}
                    setTableMatrix={setTableMatrix}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  marginLeft: "2rem",
                  position: "sticky",
                  top: "0px",
                }}
              >
                <Typography component="p" fontWeight={600}>
                  Total and Forecast by the hour
                </Typography>
                <ProductionForecastLineChart
                  averageLine={averageLine}
                  forecastLine={forecastLine}
                  meteologicaLine={meteologicaLine}
                  aiolosLine={aiolosLine}
                  forecaLine={forecaLine}
                />
                <PastForecastsChart
                  forecastDate={forecast.prognoseDate}
                  forecastType={forecast.filtersJson[0].type}
                />
                <ForecastsAbsoluteErrorChart
                  forecastType={forecast.filtersJson[0].type}
                />
              </Box>
            </Box>
          </>
        )}
    </>
  );
};

export default EditProductionForecast;
