import React, { useState } from "react";
import dayjs from "dayjs";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { grey } from "@mui/material/colors";
import { defaultStyle } from "components/Modals";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { updateProductionParkDetails } from "api/resources/productionPark";
import { fetchProductionPark } from "store/appSlice";
import { useAppDispatch } from "hooks";
import { ProductionParkComponent } from "api/models/ProductionParkComponent";
import { ProductionParkComponentType } from "api/models/enums/ProductionParkComponentType";

interface ServiceModalProps {
  productionParkId: number;
  componentId: number;
  componentType: ProductionParkComponentType;
  maxCount: number;
  maxCapacity: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ServiceModal = ({
  productionParkId,
  componentId,
  componentType,
  maxCount,
  maxCapacity,
  setOpenModal,
}: ServiceModalProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    id: 0,
    from: null,
    to: null,
    quantity: 1,
    capacity: 0,
  });

  const handleSubmit = () => {
    setLoading(true);
    const postData: Partial<ProductionParkComponent> = {
      type: componentType as ProductionParkComponentType,
      limits: [
        {
          id: formData.id,
          limitFromDate: formData.from,
          limitToDate: formData.to,
          limitCount: formData.quantity,
          limitMaxPower: formData.capacity,
        },
      ],
    };

    updateProductionParkDetails(componentId, postData).then(() => {
      dispatch(fetchProductionPark(Number(productionParkId)));
      setLoading(false);
      setOpenModal(false);
    });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Add limitation
      </Typography>
      <Typography
        id="modal-modal-title"
        component="p"
        sx={{ color: grey[700], mt: "0.6rem" }}
      >
        Set a service period for the component. This will remove the production
        capacity from the forecast generation.
      </Typography>
      <Grid container spacing={0} sx={{ mb: "2rem" }}>
        <Grid item sm={8}>
          <Grid container sx={{ mt: "2rem", mb: "1rem" }}>
            <Grid item md={6}>
              <DatePicker
                label={"From"}
                slotProps={{
                  textField: { size: "small" },
                  clearButton: { size: "small" },
                }}
                minDate={dayjs()}
                value={formData.from}
                onChange={(value) => {
                  const formDataCopy = { ...formData };

                  formDataCopy.from = dayjs(value).format("YYYY-MM-DD");

                  setFormData(formDataCopy);
                }}
                sx={{ mr: "0.8rem" }}
              />
            </Grid>
            <Grid item md={6}>
              <DatePicker
                label={"To"}
                sx={{ background: "white" }}
                slotProps={{ textField: { size: "small" } }}
                minDate={dayjs(formData.from)}
                value={formData.to}
                onChange={(value) => {
                  const formDataCopy = { ...formData };

                  formDataCopy.to = dayjs(value).format("YYYY-MM-DD");

                  setFormData(formDataCopy);
                }}
              />
            </Grid>
          </Grid>
          <Box display="flex">
            <FormControl fullWidth>
              <TextField
                id="maxCapacity"
                name="maxCapacity"
                label="Max capacity"
                size="small"
                InputProps={{ inputProps: { min: 1, max: maxCapacity } }}
                sx={{ maxWidth: "200px", mb: "1rem" }}
                value={formData.capacity}
                onChange={(e) => {
                  const formDataCopy = { ...formData };
                  const maxCapacityNumber = Number(maxCapacity);

                  if (Number(e.target.value) <= maxCapacityNumber) {
                    formDataCopy.capacity = Number(e.target.value);
                  } else {
                    formDataCopy.capacity = maxCapacityNumber;
                  }

                  setFormData(formDataCopy);
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="quantity"
                name="quantity"
                label="Quantity"
                type="number"
                size="small"
                InputProps={{ inputProps: { min: 1, max: maxCount } }}
                value={formData.quantity}
                onChange={(e) => {
                  const formDataCopy = { ...formData };

                  formDataCopy.quantity = Number(e.target.value);

                  setFormData(formDataCopy);
                }}
                sx={{ maxWidth: "100px" }}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={12} sx={{ mt: "2rem" }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            loadingIndicator="Saving..."
            onClick={() => handleSubmit()}
            disabled={loading || !formData.from}
          >
            Save limitation
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceModal;
