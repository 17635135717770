import {
  Box,
  Pagination,
  Paper,
  Skeleton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { debounce } from "lodash";
import React, {
  ChangeEvent,
  FC,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";

interface TableProps {
  data: any[];
  columns: ColumnDef<any>[];
  isFetching?: boolean;
  skeletonCount?: number;
  skeletonHeight?: number;
  pageCount?: number;
  page?: (page: number) => void;
  search?: (search: string) => void;
  searchLabel?: string;
  resetPage?: boolean;
}

const Table: FC<TableProps> = ({
  data,
  columns,
  isFetching,
  skeletonCount = 10,
  skeletonHeight = 28,
  pageCount,
  search,
  page,
  searchLabel = "Otsi",
  resetPage,
}) => {
  const [paginationPage, setPaginationPage] = useState(1);
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    if (resetPage) {
      setPaginationPage(1);
    }
  }, [resetPage]);

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      setSorting((prev) => {
        const currentSort = prev.find((sort) => sort.id === property);
        if (currentSort) {
          return currentSort.desc
            ? [{ id: property, desc: false }]
            : [{ id: property, desc: true }];
        } else {
          return [{ id: property, desc: true }];
        }
      });
    };

  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount,
    state: { sorting },
    onSortingChange: setSorting,
  });

  const skeletons = Array.from({ length: skeletonCount }, (x, i) => i);

  const columnCount = getAllColumns().length;

  const noDataFound =
    !isFetching && (!memoizedData || memoizedData.length === 0);

  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    search && search(e.target.value);
  };

  const handlePageChange = (
    event: ChangeEvent<unknown>,
    currentPage: number,
  ) => {
    setPaginationPage(currentPage === 0 ? 1 : currentPage);
    page?.(currentPage - 1);
  };

  return (
    <Box>
      {search && (
        <Box sx={{ mt: 2 }}>
          <TextField
            onChange={debounce(handleSearchChange, 1000)}
            size="small"
            label={searchLabel}
            variant="standard"
            sx={{ minWidth: "220px" }}
          />
        </Box>
      )}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <MuiTable size="small">
          {!isFetching && (
            <TableHead sx={{ bgcolor: "#eeeeee" }}>
              {getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id}>
                      <TableSortLabel
                        active={!!sorting.find((sort) => sort.id === header.id)}
                        direction={
                          sorting.find((sort) => sort.id === header.id)?.desc
                            ? "desc"
                            : "asc"
                        }
                        onClick={createSortHandler(header.id)}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
          )}
          <TableBody>
            {!isFetching
              ? getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : skeletons.map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={columnCount}>
                      <Skeleton height={skeletonHeight} />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {noDataFound && (
        <Box my={2} textAlign="center">
          No data
        </Box>
      )}
      {!noDataFound && pageCount && page && (
        <Box sx={{ mt: 4 }} display="flex" justifyContent="flex-end">
          <Pagination
            shape="rounded"
            count={pageCount}
            page={paginationPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(Table);
