import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { getProductionForecastThirtyDayAbsoluteError } from "../../../../../api/resources/productionForecasts";
import { ProductionPrognoseThirtyDayAbsoluteError } from "../../../../../api/models/ProductionForecast";

interface AbsoluteErrorChartProps {
  forecastType: string;
}

const AbsoluteErrorChart = ({ forecastType }: AbsoluteErrorChartProps) => {
  const [chartData, setChartData] = useState<
    ProductionPrognoseThirtyDayAbsoluteError[] | null
  >(null);

  const [dataset, setDataset] = useState<
    {
      day: string;
      forecaError: number;
      aiolosError: number;
      meteologicaError: number;
      realProduction: number;
    }[]
  >([]);

  useEffect(() => {
    getProductionForecastThirtyDayAbsoluteError(forecastType).then((res) => {
      setChartData(res.data);
    });
  }, [forecastType]);

  useEffect(() => {
    const chartDaysCopy: string[] = [];
    const datasetCopy: {
      day: string;
      forecaError: number;
      aiolosError: number;
      meteologicaError: number;
      realProduction: number;
    }[] = [];

    if (chartData) {
      chartData.forEach((row) => {
        chartDaysCopy.push(row.date);
        datasetCopy.push({
          day: dayjs(row.date).format("DD.MM"),
          forecaError: row.absoluteErrorForeca,
          aiolosError: row.absoluteErrorAiolos,
          meteologicaError: row.absoluteErrorMeteologica,
          realProduction: row.productionDataSum,
        });
      });

      setDataset(datasetCopy);
    }
  }, [chartData]);

  return (
    <Box sx={{ mt: "2rem" }}>
      <Grid container sx={{ mb: "1rem" }}>
        <Grid item xs={6} display="flex" alignItems="center">
          <Typography component="p" fontWeight={600}>
            Past forecasts absolute error
          </Typography>
        </Grid>
      </Grid>
      {chartData && dataset.length > 0 && (
        <BarChart
          xAxis={[{ scaleType: "band", dataKey: "day" }]}
          dataset={dataset}
          series={[
            { dataKey: "forecaError", label: "Foreca" },
            { dataKey: "aiolosError", label: "Aiolos" },
            { dataKey: "meteologicaError", label: "Meteologica" },
            { dataKey: "realProduction", label: "Real production" },
          ]}
          margin={{ left: 40, right: 0, top: 40, bottom: 80 }}
          slotProps={{
            legend: {
              position: {
                vertical: "bottom",
                horizontal: "middle",
              },
              itemMarkWidth: 15,
              itemMarkHeight: 2,
              markGap: 5,
              itemGap: 5,
            },
          }}
          width={500}
          height={300}
        />
      )}
    </Box>
  );
};

export default AbsoluteErrorChart;
