import { ProductionPark } from "../../../../../api/models/ProductionPark";

export const updateFormData = (
  prevFormData: ProductionPark,
  name: string,
  value: unknown,
  index?: number,
): ProductionPark => {
  if (index !== undefined) {
    const updatedComponents = [...prevFormData.productionParkComponents];
    updatedComponents[index] = {
      ...updatedComponents[index],
      [name]: value,
    };
    return {
      ...prevFormData,
      productionParkComponents: updatedComponents,
    };
  } else {
    return {
      ...prevFormData,
      [name]: value,
    };
  }
};
