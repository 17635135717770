import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { selectProductionParks } from "../../../../../store/selectors";
import { fetchAllProductionPark } from "../../../../../store/appSlice";
import { Box, Button, Typography } from "@mui/material";
import Table from "../../../../../components/Table";
import AddIcon from "@mui/icons-material/Add";
import { ProductionPark } from "../../../../../api/models/ProductionPark";
import { ProductionParkType } from "../../../../../api/models/enums/ProductionParkType";
import { ProtectedPaths } from "../../../../../routes";
import { Link, useNavigate } from "react-router-dom";

interface RowType {
  row: {
    original: ProductionPark;
  };
}

const ProductionParkTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const productionParks = useAppSelector(selectProductionParks);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      try {
        dispatch(fetchAllProductionPark(currentPage, 25));
      } catch (error) {
        console.error("Failed to fetch production parks:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, dispatch, setLoading]);

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (row: RowType) => (
        <span style={{ cursor: "pointer" }}>
          <Link
            to={`/${ProtectedPaths.EditProductionParks}/${row.row.original.id}`}
          >
            {" "}
            {row.row.original.name}{" "}
          </Link>
        </span>
      ),
    },
    {
      accessorKey: "code",
      header: "Code",
    },
    {
      accessorKey: "location",
      header: "Location",
    },
    {
      accessorKey: "priceArea",
      header: "Price area",
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: (row: RowType) => {
        return ProductionParkType[row.row.original.type];
      },
    },
    {
      accessorKey: "maxPowerOfInverters",
      header: "AC capacity (kWh)",
      cell: (row: RowType) => {
        return (
          Math.round(
            (row.row.original.maxPowerOfInverters + Number.EPSILON) * 100,
          ) / 100
        );
      },
    },
    {
      accessorKey: "maxPowerOfSolarPanels",
      header: "DC capacity (kWh)",
      cell: (row: RowType) => {
        return (
          Math.round(
            (row.row.original.maxPowerOfSolarPanels + Number.EPSILON) * 100,
          ) / 100
        );
      },
    },
    {
      accessorKey: "maxPowerOfWindGenerators",
      header: "Wind capacity (kW)",
      cell: (row: RowType) => {
        return (
          Math.round(
            (row.row.original.maxPowerOfWindGenerators + Number.EPSILON) * 100,
          ) / 100
        );
      },
    },
    {
      accessorKey: "maxPowerOfNetworks",
      header: "Network capacity (kWh)",
      cell: (row: RowType) => {
        return (
          Math.round(
            (row.row.original.maxPowerOfNetworks + Number.EPSILON) * 100,
          ) / 100
        );
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Production parks</Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => navigate(`/${ProtectedPaths.AddProductionParks}`)}
        >
          Add Production Park
        </Button>
      </Box>
      <Table
        data={productionParks?.content}
        isFetching={loading}
        columns={columns}
        pageCount={productionParks?.totalPages}
        page={setCurrentPage}
        searchLabel="Search for metering point"
      />
    </>
  );
};

export default ProductionParkTable;
