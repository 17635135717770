import { ProductionPark } from "api/models/ProductionPark";
import React, { useState } from "react";
import { CountryType } from "api/models/CountryType";
import { ProductionParkComponentType } from "api/models/enums/ProductionParkComponentType";
import { SelectChangeEvent } from "@mui/material/Select";
import { updateFormData } from "./updateFormData";

export const useProductionParkForm = (initialFormData: ProductionPark) => {
  const initialCountry = { code: "", label: "", phone: "" };
  const [selectedCountry, setSelectedCountry] =
    useState<CountryType>(initialCountry);
  const [selectedClients, setSelectedClients] = useState<
    { title: string; value: number }[]
  >([]);
  const [formData, setFormData] = useState<ProductionPark>(initialFormData);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    index?: number,
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) =>
      updateFormData(prevFormData, name!, value, index),
    );
  };

  const handleRemoveComponent = (index: number) => {
    setFormData((prevFormData) => {
      const updatedComponents = prevFormData.productionParkComponents.filter(
        (_, i) => i !== index,
      );
      return {
        ...prevFormData,
        productionParkComponents: updatedComponents,
      };
    });
  };

  const handleAddComponent = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      productionParkComponents: [
        ...prevFormData.productionParkComponents,
        {
          id: 0,
          type: ProductionParkComponentType.NETWORK,
          maxPower: 0,
          count: 0,
        },
      ],
    }));
  };

  const handleCountrySelect = (country: CountryType) => {
    if (country) {
      setSelectedCountry(country);
      setFormData({ ...formData, priceArea: country.code });
    }
  };

  return {
    formData,
    setFormData,
    selectedCountry,
    setSelectedCountry,
    selectedClients,
    setSelectedClients,
    handleChange,
    handleRemoveComponent,
    handleAddComponent,
    handleCountrySelect,
  };
};
