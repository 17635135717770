import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { createProductionPrognose } from "api/resources/productionForecasts";
import LoadingButton from "@mui/lab/LoadingButton";
import { ProtectedPaths } from "routes";
import { handleConfirmForecast } from "./ProductionForecastData/ProductionForecastService";
import { useAppDispatch } from "../../../../hooks";

export default function ForecastProductionParkTable({ forecasts }: any) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(null);

  const handleCreateProductionForecast = (forecast: any) => {
    setLoading(forecast.templateId);

    const postData = {
      date: forecast.prognoseDate,
      type: forecast.type,
    };

    createProductionPrognose(postData).then((result) => {
      setLoading(false);
      navigate(`/${ProtectedPaths.EditProductionForecast}/${result?.data.id}`);
    });
  };

  return (
    <TableContainer sx={{ maxWidth: 850 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Type</TableCell>
            <TableCell align="center">Created By</TableCell>
            <TableCell align="center">Updated By</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forecasts.map((forecast: any) => {
            return (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{forecast.type}</TableCell>
                <TableCell align="center">{forecast.createdByUser}</TableCell>
                <TableCell align="center">{forecast.updatedByUser}</TableCell>
                <TableCell align="center">
                  {forecast.isVerified ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CircleOutlinedIcon sx={{ color: grey[400] }} />
                  )}
                </TableCell>
                <TableCell align="center">
                  {!forecast.isVerified && forecast.createdByUser != null && (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        handleConfirmForecast(
                          forecast.prognoseId,
                          null,
                          dispatch,
                        );
                        window.location.reload();
                      }}
                    >
                      Confirm
                    </Button>
                  )}
                  <LoadingButton
                    size="small"
                    variant="text"
                    loading={loading === forecast.templateId}
                    loadingIndicator="Creating..."
                    disabled={loading === forecast.templateId}
                    onClick={
                      !forecast.createdByUser
                        ? () => handleCreateProductionForecast(forecast)
                        : () =>
                            navigate(
                              `/${ProtectedPaths.EditProductionForecast}/${forecast.prognoseId}`,
                            )
                    }
                  >
                    {forecast.createdByUser ? "Open" : "Create"}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
