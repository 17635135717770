import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { confirmForecast } from "api/resources/forecasts";
import { useAppDispatch } from "hooks";
import { fetchAllForecasts } from "store/appSlice";

import { ProtectedPaths } from "routes";
import { Forecasts } from "../../../../../api/models/Forecast";

export default function ForecastsTable({ forecasts }: Forecasts) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleConfirmForecast = (sampleId: string) => {
    confirmForecast(Number(sampleId)).then((res) => {
      dispatch(fetchAllForecasts());
    });
  };

  return (
    <TableContainer sx={{ maxWidth: 850 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sample name</TableCell>
            <TableCell align="center">Created by</TableCell>
            <TableCell align="center">Edited by</TableCell>
            <TableCell align="center">Confirmed</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forecasts &&
            forecasts
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((forecast) => {
                const isTemplate = forecast.isTemplate;

                return (
                  <TableRow
                    key={forecast.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="forecast">
                      {forecast.name}
                    </TableCell>
                    <TableCell align="center">
                      {forecast.createdByUser}
                    </TableCell>
                    <TableCell align="center">
                      {forecast.updatedByUser}
                    </TableCell>
                    <TableCell align="center">
                      {forecast.isVerified ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CircleOutlinedIcon sx={{ color: grey[400] }} />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {!isTemplate && !forecast.isVerified && (
                        <Button
                          size="small"
                          variant="text"
                          onClick={() =>
                            handleConfirmForecast(forecast.id.toString())
                          }
                        >
                          Confirm
                        </Button>
                      )}
                      <Button
                        size="small"
                        variant="text"
                        onClick={
                          isTemplate
                            ? () =>
                                navigate(
                                  `/${ProtectedPaths.CreateConsumptionForecast}/${forecast.id}/${forecast.prognoseDate}`,
                                )
                            : () => navigate(forecast.id.toString())
                        }
                      >
                        {isTemplate ? "Create" : "Open"}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
