import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProtectedPaths } from "routes";
import MainLayout from "layouts/Main";
import { Box, Container, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import TextField from "components/TextField";
import {
  createForecastTemplate,
  getForecastTemplate,
  updateForecastTemplate,
} from "api/resources/forecasts";
import Filters from "../../Filters";
import dayjs from "dayjs";
import {
  ForecastFormValuesProps,
  ForecastTemplate,
} from "../../../../../api/models/Forecast";

const EditSavedSample = () => {
  const navigate = useNavigate();
  const { sampleId } = useParams();
  const [forecast, setForecast] = useState<ForecastTemplate>(null);
  const [sampleName, setSampleName] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<ForecastFormValuesProps>({
    customDates: [],
    selectedClients: [],
    selectedMeteringPointsOptions: [],
  });

  useEffect(() => {
    if (sampleId) {
      getForecastTemplate(sampleId)
        .then((response) => {
          setForecast(response.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (forecast) {
      setSampleName(forecast.name);

      let newFormValues = formValues;

      newFormValues.selectedClients =
        forecast.filters[0].clients.map((c) => {
          return c.id;
        }) || [];
      newFormValues.selectedMeteringPointsOptions =
        forecast.filters[0].meteringPoints.map((mp) => {
          return mp.id;
        }) || [];

      console.log({ newFormValues });

      setFormValues(newFormValues);
    }
  }, [forecast]);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const sampleData = {
      name: sampleName,
      filters: [
        {
          clients: formValues.selectedClients,
          meteringPoints: formValues.selectedMeteringPointsOptions,
          customDates: formValues.customDates.map((date) =>
            dayjs(date).format("YYYY-MM-DD"),
          ),
        },
      ],
    };

    if (sampleId) {
      updateForecastTemplate(parseInt(sampleId), sampleData)
        .then(() => {
          setLoading(false);
          setSnackbar({
            type: "success",
            message: "Forecast sample saved",
          });
        })
        .catch((error) => {
          console.log({ error });
          setLoading(false);
          setSnackbar({
            type: "error",
            message: "Something went wrong",
          });
        });
    } else {
      createForecastTemplate(sampleData)
        .then(() => navigate(`/${ProtectedPaths.SavedSamples}`))
        .catch((error) => {
          console.log({ error });
          setSnackbar({
            type: "error",
            message: "Something went wrong",
          });
        });
    }
  };

  const showFilters = sampleId ? forecast : true;

  return (
    <MainLayout
      tabs={[
        { label: "Manage samples", path: "Consumption" },
        { label: "Saved samples", path: "SavedSamples" },
      ]}
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.SavedSamples}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          {forecast ? "Edit forecast sample" : "Add forecast sample"}
          {forecast && (
            <span style={{ color: grey[500] }}>{` - ${forecast.name}`}</span>
          )}
        </Typography>
      </Box>
      <Box>
        <Container
          maxWidth="md"
          sx={{ background: "#f4f4f4", margin: 0, mt: "1rem", padding: "1rem" }}
        >
          <TextField
            id="sampleName"
            value={sampleName}
            onChange={(e: any) => setSampleName(e.target.value)}
            label="Sample title"
            minWidth={360}
          />
        </Container>
        {showFilters && (
          <Filters
            noCustomDates
            formValues={formValues}
            setFormValues={setFormValues}
            handleSubmit={handleSubmit}
            submitText="Save"
            handleCancel={() => navigate(`/${ProtectedPaths.SavedSamples}`)}
            loading={loading}
            cancelText="Cancel"
            showButtons={true}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default EditSavedSample;
